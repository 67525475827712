export const projectsCategories = [
  {
    id: 0,
    title: "Todos",
    selected: true,
    key: 'category'
  },
  {
    id: 1,
    title: "Front-End",
    selected: false,
    key: 'category'
  },
  {
    id: 2,
    title: "Mobile",
    selected: false,
    key: 'category'
  },
  {
    id: 3,
    title: "Jogos",
    selected: false,
    key: 'category'
  },
  {
    id: 4,
    title: "Outros",
    selected: false,
    key: 'category'
  }
]